<template>
  <div class="view-container">
    <div
        class="view"
    >
      <div class="view-body">
        <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loading && !formLoaded"
        >
          <v-data-table
              :footer-props="dataTableOptions.footerProps"
              :options.sync="dataTableOptions"
              :server-items-length="dataTableOptions.totalItems"
              :headers="headers"
              :items="data"
              :loading="loading"
              class="elevation-0 clickable-row"
              @click:row="viewVisit"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-btn
                    normal
                    class="buttonCustom"
                    dark
                    elevation="0"
                    @click="toggleCreateVisit"
                >
                  <v-icon
                      size="17"
                      class="mr-2"
                  >fas fa-plus
                  </v-icon>
                  {{ $t('system.add_visit') }}
                </v-btn>
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-spacer />
                <SimpleGroupBy
                    :group-by="dataTableOptions.groupBy[0]"
                    :group-by-elements="groupByElements"
                    :default-group-by="'planned_at'"
                    @setGroupBy="setGroupBy"
                />
                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />
                <v-btn
                    class="mt-1 mr-1"
                    color="secondary"
                    small
                    text
                    @click.stop="fetchData(true)"
                >
                  <v-icon size="17">fas fa-sync-alt</v-icon>
                </v-btn>
                <SimpleStaticFilters
                    :filter-params="filterParams"
                    @setFilters="setFilters"
                />
              </v-toolbar>
            </template>
            <template v-slot:item.planned_at_time="{ item }">
              {{ item.planned_at }} {{ item.planned_at_time }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn
                  @click.stop="toggleEditVisit(item.id)"
                  class="ml-2"
                  color="primary"
                  dark
                  icon
                  small
              >
                <v-icon size="15">
                  fas fa-edit
                </v-icon>
              </v-btn>
              <v-btn
                  @click.stop="setStepsVisitId(item.id)"
                  class="ml-2"
                  color="primary"
                  dark
                  icon
                  small
              >
                <v-icon size="15">
                  fas fa-shoe-prints
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:no-data>
              {{ $t('system.no_data') }}
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <VisitDetails
          :data="visitData"
          :dialog="viewDialog"
          :loading="dialogLoading"
          @close="close"
          @edit="toggleEditVisitAlt"
      />
      <EditCreateVisit
          :data="editVisitData"
          :dialog="editDialog"
          :loading="dialogLoading"
          :create="create"
          @close="close"
          @save="saveVisit"
      />
      <VisitSteps
        :visitId="stepsVisitId"
        @close="setStepsVisitId(null)"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import SimpleGroupBy from '@/components/Filters/SimpleGroupBy';
import SimpleStaticFilters from '@/components/Filters/SimpleStaticFilters';
import VisitDetails from '@/components/Visits/VisitDetails';
import EditCreateVisit from '@/components/Visits/EditCreateVisit';
import VisitSteps from '@/components/Visits/VisitSteps/Index';

export default {
  name: 'VisitsList',

  components: {
    VisitSteps,
    SimpleGroupBy,
    SimpleStaticFilters,
    VisitDetails,
    EditCreateVisit,
  },

  data() {
    return {
      loading: true,
      formLoaded: false,
      viewDialog: false,
      editDialog: false,
      stepsVisitId: null,
      create: false,
      groupByElements: ['planned_at', 'account_name', 'account_group'],
      filterParams: [
        {
          type: 'date-range',
          name: ['visit_date_from', 'visit_date_to'],
          menu: false,
          value: [],
        }, {
          type: 'autocomplete',
          name: 'account_group',
          items: [],
          value: null,
        }, {
          type: 'autocomplete',
          name: 'account_name',
          items: [],
          value: null,
        },
      ],
      data: [],
      visitData: {},
      editVisitData: {},
      accounts: [],
      groups: [],
      dialogLoading: true,
      dataTableOptions: {
        page: 1,
        itemsPerPage: localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`) ? parseInt(JSON.parse((localStorage.getItem(`${this.$route.name.trim()}_ItemsPerPage`))), 10) : 10,
        sortBy: ['planned_at'],
        sortDesc: [true],
        groupBy: localStorage.getItem(`${this.$route.name.trim()}_GroupBy`) && JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_GroupBy`)) ? [JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_GroupBy`))] : [],
        groupDesc: [true],
        multiSort: false,
        mustSort: false,
        totalItems: 0,
        footerProps: {
          itemsPerPageOptions: [10, 25, 50, 100],
          showFirstLastPage: true,
        },
      },
      headers: [
        {
          text: this.$t('system.account_group'),
          sortable: true,
          value: 'account_group',
        },
        {
          text: this.$t('system.account_name'),
          sortable: true,
          value: 'account_name',
        },
        {
          text: this.$t('system.address'),
          sortable: true,
          value: 'address',
        },
        {
          text: this.$t('system.account_status_name'),
          sortable: true,
          value: 'account_status_name',
        },
        {
          text: this.$t('system.planned_at'),
          sortable: true,
          value: 'planned_at_time',
        },
        {
          text: this.$t('system.actions'),
          sortable: false,
          value: 'actions',
          align: 'end',
        },
      ],
      requestUrl: '',
    };
  },

  watch: {
    dataTableOptions: {
      handler() {
        if (this.formLoaded) {
          this.prepareQuery();
          this.fetchData();
          localStorage.setItem(`${this.$route.name.trim()}_ItemsPerPage`, JSON.stringify(this.dataTableOptions.itemsPerPage));
        }
      },
      deep: true,
    },
    $route() {
      if (this.$route.params.id) {
        this.getVisit(this.$route.params.id);
      }
    },
  },

  beforeMount() {
    this.fetchData(true);
    if (this.$route.name === 'AddVisit') {
      this.viewDialog = true;
      this.create = true;
    } else if (this.$route.params.id) {
      this.viewDialog = true;
      this.getVisit(this.$route.params.id);
    }
  },

  methods: {
    async fetchData(deep = false) {
      this.loading = true;
      if (deep) {
        this.fetchFilterForms();
      }
      try {
        const {data} = await axios.get(`/sk_visits/visit${this.requestUrl}`, {
          params: {
            per_page: this.dataTableOptions.itemsPerPage,
            page: this.dataTableOptions.page,
            direction: this.dataTableOptions.sortDesc[0] ? 'desc' : 'asc',
            column: this.dataTableOptions.sortBy[0],
          },
        });
        this.data = this.parseObj(data.data);
        this.dataTableOptions.totalItems = data.total;
        this.formLoaded = true;
        this.loading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
      }
    },

    prepareQuery() {
      this.requestUrl = `?order=${this.dataTableOptions.groupBy[0] ? this.dataTableOptions.groupBy[0] === 'account_group' ? 'groups' : this.dataTableOptions.groupBy[0] : 'planned_at'}`;
      this.filterParams.forEach((filterParam) => {
        if (filterParam.name === 'account_name' && filterParam.value) {
          filterParam.value.forEach((element) => {
            this.requestUrl += `&account_ids[]=${element}`;
          });
        } else if (filterParam.name === 'account_group' && filterParam.value) {
          filterParam.value.forEach((element) => {
            this.requestUrl += `&account_groups_ids[]=${element}`;
          });
        } else if (Array.isArray(filterParam.name) && filterParam.value) {
          if (filterParam.name[0] === 'visit_date_from' && filterParam.value[0]) {
            this.requestUrl += `&visit_date_from=${filterParam.value[0]}`;
          }
          if (filterParam.name[1] === 'visit_date_to' && filterParam.value[1]) {
            this.requestUrl += `&visit_date_to=${filterParam.value[1]}`;
          }
        }
      });
    },

    async getVisit(id) {
      this.viewDialog = !this.editDialog;
      this.dialogLoading = true;
      try {
        const {data} = await axios.get(`/sk_visits/visit/${id}${this.editDialog ? '/edit' : ''}`);
        this.editDialog ? this.editVisitData = data : this.visitData = data.data;
        this.loading = false;
        this.dialogLoading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
        this.dialogLoading = false;
      }
    },

    viewVisit(element) {
      this.visitData = element;
      if (this.$route.params.id !== element.id) {
        this.$router.push({
          path: `/visits/${element.id}`,
        });
      }
    },

    close() {
      this.$router.push({
        path: `/visits`,
      });
      this.viewDialog = false;
      this.editDialog = false;
      this.dialogLoading = false;
      this.editVisitData = {
        accounts: [],
        users: [],
        visit: {
          account_id: null,
          address_id: null,
          contact_id: null,
          visit_type_id: null,
          assigned_to: null,
          start_at: null,
          planned_at: null,
        },
      };
    },

    setStepsVisitId(id) {
      this.stepsVisitId = id
    },

    toggleEditVisit(id) {
      this.editDialog = true;
      this.viewDialog = false;
      if (this.$route.params.id !== id) {
        this.$router.push({
          path: `/visits/${id}`,
        });
      }
    },

    toggleEditVisitAlt(id) {
      this.editDialog = true;
      this.viewDialog = false;
      this.getVisit(id);
    },

    async toggleCreateVisit() {
      this.editDialog = true;
      this.viewDialog = false;
      this.dialogLoading = true;
      try {
        const {data} = await axios.get(`/sk_visits/visit/create`);
        this.editVisitData = data;
        this.loading = false;
        this.dialogLoading = false;
      } catch (error) {
        console.log('error', error);
      } finally {
        this.loading = false;
        this.dialogLoading = false;
      }
    },

    saveVisit(data) {
      const obj = {
        account_id: data.data.account_id,
        address_id: data.data.address_id,
        contact_id: data.data.contact_id,
        visit_type_id: data.data.visit_type_id,
        visit_status_id: data.data.visit_status_id,
        assigned_to: data.data.assigned_to,
        planned_at: data.data.planned_at.toISOString(),
      };
      if (data.visitId) {
        this.editVisit(data.visitId, obj);
      } else {
        this.createVisit(obj);
      }
    },

    async editVisit(id, obj) {
      this.dialogLoading = true;
      try {
        await axios.put(`/sk_visits/visit/${id}`, obj);
        await this.fetchData();
        this.dialogLoading = false;
        this.close();
      } catch (error) {
        console.log('error', error);
        this.dialogLoading = false;
      }
    },

    async createVisit(obj) {
      this.dialogLoading = true;
      try {
        await axios.post(`/sk_visits/visit`, obj);
        await this.fetchData();
        this.dialogLoading = false;
        this.close();
      } catch (error) {
        console.log('error', error);
        this.dialogLoading = false;
      }
    },

    fetchFilterForms() {
      const savedParams = localStorage.getItem(`${this.$route.name.trim()}_Filters`) ? JSON.parse(localStorage.getItem(`${this.$route.name.trim()}_Filters`)) : null;
      if (savedParams)
        this.filterParams.forEach((param) => {
          savedParams.forEach((localParam) => {
            if (localParam.name === param.name) {
              param.value = localParam.value;
            }
          });
        });
      this.prepareQuery();
      this.getAccounts();
      this.getAccountsGroups();
    },

    async getAccounts() {
      try {
        const {data} = await axios.get('/sk_visits/accounts');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_name') {
            element.items = data;
          }
        });
        this.accounts = data;
      } catch (error) {
        console.log('error', error);
      }
    },

    async getAccountsGroups() {
      try {
        const {data} = await axios.get('/sk_visits/groups');
        this.filterParams.forEach((element) => {
          if (element.name === 'account_group') {
            element.items = data;
          }
        });
        this.groups = data;
      } catch (error) {
        console.log('error', error);
      }
    },

    parseObj(obj) {
      let arr = [];
      Object.values(obj).forEach((parent) => {
        parent.forEach((child) => {
          child.planned_at_time = child.planned_at.split(' ')[1];
          child.planned_at = child.planned_at.split(' ')[0];
          arr.push(child);
        });
      });
      return arr;
    },

    setGroupBy(groupBy) {
      if (this.dataTableOptions.groupBy[0] !== groupBy) {
        this.loading = true;
        this.dataTableOptions.groupBy = groupBy ? [groupBy] : [];
        this.prepareQuery();
        this.fetchData();
        localStorage.setItem(`${this.$route.name.trim()}_GroupBy`, JSON.stringify(groupBy));
      }
    },

    setFilters(filterParams) {
      this.filterParams = filterParams;
      const copy = JSON.parse(JSON.stringify(filterParams));
      copy.forEach((element) => {
        if (element.items) delete element.items;
      });
      this.loading = true;
      this.prepareQuery();
      this.fetchData();
      localStorage.setItem(`${this.$route.name.trim()}_Filters`, JSON.stringify(copy));
    },
  },
};
</script>
