<template>
  <v-card>
    <v-card-title>{{title}}</v-card-title>
    <slot />
  </v-card>
</template>

<script>
export default {
  name: 'StepTemplate',
  props: {
    title: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>
