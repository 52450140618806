<template>
  <v-dialog
    :value="!!visitId"
    :persistent="true"
    max-width="700px"
  >
    <v-card v-if="!!visitId">
      <v-card-title class="justify-space-between">
        <v-skeleton-loader
          type="table-cell"
          :loading="loading"
          style="background-color: white"
        >
          <span>{{ $t('system.visit_steps')}}</span>
        </v-skeleton-loader>
        <v-btn
          class="mt-1 ml-3"
          color="secondary"
          text
          fab
          small
          @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="my-0"/>
      <v-skeleton-loader
        :class="{' mt-4 ml-4 mr-4': loading}"
        type="article, actions"
        :loading="loading"
        style="background-color: white"
      >
        <v-card-text class="card-text">
          <v-tabs
            v-model="tab"
            align-with-title
            show-arrows
          >
<!--            <v-tabs-slider />-->
            <v-tab
              v-for="item in steps"
              :key="item"
            >
              {{ item }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="item in steps"
              :key="item"
            >
              <StepTemplate
                :title="title"
                class="mt-3"
              >
                <v-card-subtitle>
                  {{$t(`system.${item}`)}}
                </v-card-subtitle>
                <v-card-text>
                  <component
                    :is="getComponentName(item)"
                    :visitId="visitId"
                  />
                </v-card-text>
              </StepTemplate>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import StepTemplate from "@/components/Visits/VisitSteps/StepTemplate";
import {camelCaseToUnderscoreCase, capitalize, underscoreCaseToCamelCase} from "@/helpers/functions";
import notification from "@/notification/notify";

export default {
  name: 'VisitSteps',
  components: {
    ServicesStep: () => import('@/components/Visits/VisitSteps/steps/ServicesStep'),
    GiftsStep: () => import('@/components/Visits/VisitSteps/steps/GiftsStep'),
    FreebiesStep: () => import('@/components/Visits/VisitSteps/steps/FreebiesStep'),
    OrderStep: () => import('@/components/Visits/VisitSteps/steps/OrderStep'),
    ConversationStep: () => import('@/components/Visits/VisitSteps/steps/ConversationStep'),
    PromotionalCampaignsStep: () => import('@/components/Visits/VisitSteps/steps/PromotionalCampaignsStep'),
    QuantityRatioStep: () => import('@/components/Visits/VisitSteps/steps/QuantityRatioStep'),
    ExposureStep: () => import('@/components/Visits/VisitSteps/steps/ExposureStep'),
    InventoryStep: () => import('@/components/Visits/VisitSteps/steps/InventoryStep'),
    SummaryStep: () => import('@/components/Visits/VisitSteps/steps/SummaryStep'),
    StepTemplate
  },
  props: {
    visitId: {
      type: [Number, String],
    },
  },
  data: () => ({
    title: 'BlaBlaBla',
    loading: false,
    tab: null,
    steps: [],
    realizationStatus: '',
  }),
  watch: {
    visitId(value) {
      if (value) {
        this.tab = null;
        this.fetchSteps()
      }
    }
  },
  methods: {
    getComponentName(step) {
      return `${capitalize(underscoreCaseToCamelCase(step))}Step`
    },
    async fetchSteps() {
      try {
        this.loading = true
        const {data} = await axios.post(`sk_visits/${this.visitId}/start`, {
          distance: 0.5
        })
        data.steps.push('summary')
        this.steps = data.steps
        this.realizationStatus = data.realizations_status
      } catch (e) {
        notification.notify(
          this.$t('notify.error'),
          this.$t('system.can_not_load_steps'),
          'error');
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>
