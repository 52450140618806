<template>
  <v-dialog
      :value="dialog"
      :persistent="true"
      max-width="700px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        <v-skeleton-loader
            type="table-cell"
            :loading="loading"
            style="background-color: white"
        >
          <span v-if="this.data.visit && this.data.visit.id">{{ $t('system.edit_visit') }}</span>
          <span v-else>{{ $t('system.create_visit') }}</span>
        </v-skeleton-loader>
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-2 mb-6" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text"
        >
          <v-form
              @keyup.native.enter="valid && save()"
              v-model="valid"
              ref="form"
          >
            <v-autocomplete
                v-model="formData.account_id"
                :items="data.accounts"
                :label="$t('system.account_name')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                @change="getAssignedData"
            />
            <v-autocomplete
                v-model="formData.contact_id"
                :items="contacts"
                :label="$t('system.contact')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                :loading="contactsLoading ? 'blue' : false"
                :readonly="!formData.account_id || contactsLoading || contacts.length === 0"
                :disabled="!formData.account_id || contactsLoading || contacts.length === 0"
                :hint="!formData.account_id ? `${$t('system.account_name_is_required')}`: formData.account_id && contacts.length === 0 ? `${$t('system.no_data')}` : ''"
                persistent-hint
            />
            <v-autocomplete
                v-model="formData.address_id"
                :items="addresses"
                :label="$t('system.address')"
                :rules="[rules.required]"
                item-text="address"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
                :loading="addressesLoading ? 'blue' : false"
                :readonly="!formData.account_id || contactsLoading || contacts.length === 0"
                :disabled="!formData.account_id || contactsLoading || contacts.length === 0"
                :hint="!formData.account_id ? `${$t('system.account_name_is_required')}`: formData.account_id && contacts.length === 0 ? `${$t('system.no_data')}` : ''"
                persistent-hint
            />
            <v-autocomplete
                v-model="formData.assigned_to"
                :items="data.users"
                :label="$t('system.assigned_to')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
            />
            <v-datetime-picker
                :label="$t('system.planned_at')"
                :clearText="$t('system.clear')"
                :okText="$t('system.save')"
                firstDayOfWeek="1"
                :textFieldProps="{outlined: true, dense: true, 'append-icon': 'fas fa-calendar', rules: [rules.required]}"
                :timePickerProps="{format: '24hr'}"
                v-model="formData.planned_at"
            >
              <template slot="dateIcon">
                <v-icon
                    size="17"
                    class="mr-2"
                >fas fa-calendar
                </v-icon>
                {{ $t('system.date') }}
              </template>
              <template slot="timeIcon">
                <v-icon
                    size="17"
                    class="mr-2"
                >fas fa-clock
                </v-icon>
                {{ $t('system.time') }}
              </template>
            </v-datetime-picker>
            <v-autocomplete
                v-model="formData.visit_type_id"
                :items="data.visitTypes"
                :label="$t('system.visit_type')"
                :rules="[rules.required]"
                item-text="name"
                item-value="id"
                auto-select-first
                dense
                outlined
                flat
            />
          </v-form>
        </v-card-text>
        <v-divider
            class="mt-0 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="primary"
              @click="close"
          >
            {{ $t('system.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              @click="save"
          >
            {{ $t('system.save') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>

import axios from 'axios';

export default {
  name: 'EditCreateVisit',

  data() {
    return {
      dialogStatus: false,
      menu1: false,
      menu2: false,
      valid: false,
      contactsLoading: false,
      addressesLoading: false,
      accounts: [],
      contacts: [],
      addresses: [],
      formData: {
        account_id: null,
        address_id: null,
        contact_id: null,
        visit_type_id: null,
        assigned_to: null,
        planned_at: null,
      },
      rules: {
        required: value => !!value || this.$t('field_must_be_valid'),
      },
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
        return {
          accounts: [],
          users: [],
          visitTypes: [],
          visit: {
            id: null,
          },
        };
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    data: {
      handler() {
        if (this.data.visit && this.data.visit.id) {
          this.formData = JSON.parse(JSON.stringify(this.data.visit));
          if (this.formData.start_at) {
            this.formData.start_at = new Date(this.formData.start_at);
          }
          if (this.formData.planned_at) {
            this.formData.planned_at = new Date(this.formData.planned_at);
          }
        }
        this.getAssignedData();
      },
      deep: true,
    },
    dialog: {
      handler(val) {
        if (!val) {
          this.clearFormData();
        }
      },
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },

    save() {
      this.$refs.form.validate();
      if (this.valid) {
        this.$emit('save', {data: this.formData, visitId: this.data.visit ? this.data.visit.id : null});
        this.$refs.form.resetValidation();
      }
    },

    getAssignedData() {
      this.$refs.form.resetValidation();
      if (this.formData.account_id) {
        this.getAddresses(this.formData.account_id);
        this.getContacts(this.formData.account_id);
      }
    },

    async getAddresses(id) {
      this.addressesLoading = true;
      try {
        const response = await axios.get(`/sk_visits/address/${id}`);
        this.addresses = response.data;
        this.addressesLoading = false;
      } catch (error) {
        console.log('error', error);
        this.addressesLoading = false;
      }
    },

    async getContacts(id) {
      this.contactsLoading = true;
      try {
        const response = await axios.get(`/sk_visits/contacts/${id}`);
        this.contacts = response.data;
        this.contactsLoading = false;
      } catch (error) {
        console.log('error', error);
        this.contactsLoading = false;
      }
    },

    clearFormData() {
      this.formData = {
        account_id: null,
        address_id: null,
        contact_id: null,
        visit_type_id: null,
        assigned_to: null,
        planned_at: null,
      };
    },
  },
};
</script>
