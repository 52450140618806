<template>
  <v-dialog
      :value="dialog"
      @click:outside="close"
      max-width="800px"
  >
    <v-card>
      <v-card-title class="justify-space-between">
        {{ $t('system.visit_details') }}
        <v-btn
            class="mt-1 ml-3"
            color="secondary"
            text
            fab
            small
            @click.stop="close"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-1 mb-2" />
      <v-skeleton-loader
          :class="{' mt-4 ml-4 mr-4': loading}"
          type="article, actions"
          :loading="loading"
          style="background-color: white"
      >
        <v-card-text
            class="card-text pb-2 pl-6 pr-6"
        >
          <p class="text--primary title mb-2">
            {{ data.account_name }}
          </p>
          <v-spacer />
          <p class="text--primary mb-2">
            <v-icon
                size="16"
                class="mr-2"
                style="width: 24px; margin-top: -3px"
            >fas fa-map-marker
            </v-icon>
            {{ data.address }}
          </p>
          <p class="text--primary">
            <v-icon
                size="16"
                class="mr-2"
                style="width: 24px; margin-top: -3px"
            >fas fa-clock
            </v-icon>
            {{ data.planned_at }}
          </p>
          <div
              v-if="data.visit_status"
          >
            <div>
              <v-chip
              >
                {{ data.visit_status }}
              </v-chip>
            </div>
          </div>
          <v-row>
            <v-col
                cols="12"
                md="6"
                class="mt-2"
            >
              <div
                  v-for="(key, index) in Object.keys(data)"
                  :key="index"
              >
                <div v-if="isInColumn(key, 'first')">
                  <v-spacer />
                  <div>{{ $t(`system.${key}`) }}</div>
                  <p class="text--primary">
                    {{ data[key] ? data[key] : $t('system.no_data') }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="mt-2"
            >
              <div
                  v-for="(key, index) in Object.keys(data)"
                  :key="index"
              >
                <div v-if="isInColumn(key, 'second')">
                  <v-spacer />
                  <div>{{ $t(`system.${key}`) }}</div>
                  <p
                      v-if="data[key] && data[key].value"
                      class="text--primary d-flex"
                  >
                    {{ data[key].value }}
                    <span
                        v-if="key === 'average_three_months'"
                        class="d-flex ml-2"
                    >
                      <span
                          class="month-sales-badge"
                          :class="{'active': data[key].first_month}"
                      >
                        1
                      </span>
                      <span
                          class="month-sales-badge"
                          :class="{'active': data[key].second_month}"
                      >
                        2
                      </span>
                      <span
                          class="month-sales-badge"
                          :class="{'active': data[key].third_month}"
                      >
                        3
                      </span>
                    </span>
                  </p>
                  <p
                      v-else-if="data[key] && data[key].planned_at"
                      class="text--primary d-flex"
                  >
                    <span
                        class="d-flex flex-column ml-2"
                    >
                      <span>
                        {{ 'system.planned_at' }}: {{ data[key].planned_at }}
                      </span>
                      <span>
                        {{ 'system.start_at' }}: {{ data[key].planned_at }}
                      </span>
                      <span>
                        {{ 'system.end_at' }}: {{ data[key].planned_at }}
                      </span>
                      <span>
                        <small>{{ 'system.note' }}:</small><br>
                        {{ data[key].note || 'brak' }}
                      </span>
                    </span>
                  </p>
                  <p
                      v-else
                      class="text--primary"
                  >
                    {{ data[key] ? data[key] : $t('system.no_data') }}
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider
            class="mt-1 mb-0"
        />
        <v-card-actions
            class="pb-3 pt-3 pr-3 d-flex justify-content-end"
        >
          <v-btn
              text
              color="primary"
              @click="toggleEdit"
          >
            {{ $t('system.edit') }}
          </v-btn>
        </v-card-actions>
      </v-skeleton-loader>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'VisitDetails',

  data() {
    return {
      dialogStatus: false,
      accounts: [],
    };
  },

  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async toggleEdit() {
      this.$emit('edit', this.data.id);
    },

    close() {
      this.$emit('close');
    },

    isInColumn(key, column) {
      const first = ['account_group', 'account_priority', 'route', 'visit_type', 'contact', 'contact_phone', 'assigned_to'];
      const second = ['target', 'last_month_sales', 'average_three_months', 'current_month_sales', 'last_visit'];
      if (column === 'first') {
        return first.find(element => element === key);
      } else if (column === 'second') {
        return second.find(element => element === key);
      }
    },
  },
};
</script>

<style
    scoped
    lang="scss"
>

.text--primary {
  font-size: 17px;

  &.title {
    font-size: 30px;
  }
}

</style>
